import "./src/global.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

config.autoAddCss = false;

export const onRouteUpdate = ({ prevLocation }) => {
  if (prevLocation) {
    sessionStorage.setItem("previousUrl", prevLocation.href);
  }
};
