exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-history-jsx": () => import("./../../../src/pages/about/history.jsx" /* webpackChunkName: "component---src-pages-about-history-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-team-jsx": () => import("./../../../src/pages/about/team.jsx" /* webpackChunkName: "component---src-pages-about-team-jsx" */),
  "component---src-pages-allfunds-data-analytics-jsx": () => import("./../../../src/pages/allfunds-data-analytics.jsx" /* webpackChunkName: "component---src-pages-allfunds-data-analytics-jsx" */),
  "component---src-pages-annual-report-2024-index-js": () => import("./../../../src/pages/annual-report-2024/index.js" /* webpackChunkName: "component---src-pages-annual-report-2024-index-js" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-campaign-signup-2023-jsx": () => import("./../../../src/pages/campaign/signup2023.jsx" /* webpackChunkName: "component---src-pages-campaign-signup-2023-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-client-care-jsx": () => import("./../../../src/pages/client-care.jsx" /* webpackChunkName: "component---src-pages-client-care-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-data-protection-jsx": () => import("./../../../src/pages/data-protection.jsx" /* webpackChunkName: "component---src-pages-data-protection-jsx" */),
  "component---src-pages-distributors-alternatives-jsx": () => import("./../../../src/pages/distributors/alternatives.jsx" /* webpackChunkName: "component---src-pages-distributors-alternatives-jsx" */),
  "component---src-pages-distributors-blockchain-jsx": () => import("./../../../src/pages/distributors/blockchain.jsx" /* webpackChunkName: "component---src-pages-distributors-blockchain-jsx" */),
  "component---src-pages-distributors-index-jsx": () => import("./../../../src/pages/distributors/index.jsx" /* webpackChunkName: "component---src-pages-distributors-index-jsx" */),
  "component---src-pages-distributors-investment-jsx": () => import("./../../../src/pages/distributors/investment.jsx" /* webpackChunkName: "component---src-pages-distributors-investment-jsx" */),
  "component---src-pages-esg-approach-jsx": () => import("./../../../src/pages/esg/approach.jsx" /* webpackChunkName: "component---src-pages-esg-approach-jsx" */),
  "component---src-pages-esg-policies-statements-jsx": () => import("./../../../src/pages/esg/policies-statements.jsx" /* webpackChunkName: "component---src-pages-esg-policies-statements-jsx" */),
  "component---src-pages-esg-reports-jsx": () => import("./../../../src/pages/esg/reports.jsx" /* webpackChunkName: "component---src-pages-esg-reports-jsx" */),
  "component---src-pages-esg-strategic-pillars-jsx": () => import("./../../../src/pages/esg/strategic-pillars.jsx" /* webpackChunkName: "component---src-pages-esg-strategic-pillars-jsx" */),
  "component---src-pages-esg-sustainability-navigator-jsx": () => import("./../../../src/pages/esg/sustainability-navigator.jsx" /* webpackChunkName: "component---src-pages-esg-sustainability-navigator-jsx" */),
  "component---src-pages-fund-houses-alternatives-jsx": () => import("./../../../src/pages/fund-houses/alternatives.jsx" /* webpackChunkName: "component---src-pages-fund-houses-alternatives-jsx" */),
  "component---src-pages-fund-houses-index-jsx": () => import("./../../../src/pages/fund-houses/index.jsx" /* webpackChunkName: "component---src-pages-fund-houses-index-jsx" */),
  "component---src-pages-fund-houses-investment-jsx": () => import("./../../../src/pages/fund-houses/investment.jsx" /* webpackChunkName: "component---src-pages-fund-houses-investment-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investors-about-jsx": () => import("./../../../src/pages/investors/about.jsx" /* webpackChunkName: "component---src-pages-investors-about-jsx" */),
  "component---src-pages-investors-contact-jsx": () => import("./../../../src/pages/investors/contact.jsx" /* webpackChunkName: "component---src-pages-investors-contact-jsx" */),
  "component---src-pages-investors-faqs-jsx": () => import("./../../../src/pages/investors/faqs.jsx" /* webpackChunkName: "component---src-pages-investors-faqs-jsx" */),
  "component---src-pages-investors-financial-bank-reports-jsx": () => import("./../../../src/pages/investors/financial/bank-reports.jsx" /* webpackChunkName: "component---src-pages-investors-financial-bank-reports-jsx" */),
  "component---src-pages-investors-financial-calendar-jsx": () => import("./../../../src/pages/investors/financial/calendar.jsx" /* webpackChunkName: "component---src-pages-investors-financial-calendar-jsx" */),
  "component---src-pages-investors-financial-financial-reports-jsx": () => import("./../../../src/pages/investors/financial/financial-reports.jsx" /* webpackChunkName: "component---src-pages-investors-financial-financial-reports-jsx" */),
  "component---src-pages-investors-financial-ipo-jsx": () => import("./../../../src/pages/investors/financial/ipo.jsx" /* webpackChunkName: "component---src-pages-investors-financial-ipo-jsx" */),
  "component---src-pages-investors-governance-bank-index-js": () => import("./../../../src/pages/investors/governance/bank/index.js" /* webpackChunkName: "component---src-pages-investors-governance-bank-index-js" */),
  "component---src-pages-investors-governance-group-index-js": () => import("./../../../src/pages/investors/governance/group/index.js" /* webpackChunkName: "component---src-pages-investors-governance-group-index-js" */),
  "component---src-pages-investors-index-js": () => import("./../../../src/pages/investors/index.js" /* webpackChunkName: "component---src-pages-investors-index-js" */),
  "component---src-pages-investors-share-jsx": () => import("./../../../src/pages/investors/share.jsx" /* webpackChunkName: "component---src-pages-investors-share-jsx" */),
  "component---src-pages-investors-shareholder-meetings-jsx": () => import("./../../../src/pages/investors/shareholder-meetings.jsx" /* webpackChunkName: "component---src-pages-investors-shareholder-meetings-jsx" */),
  "component---src-pages-legal-advice-cookies-policy-jsx": () => import("./../../../src/pages/legal-advice/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-legal-advice-cookies-policy-jsx" */),
  "component---src-pages-legal-advice-legal-notice-jsx": () => import("./../../../src/pages/legal-advice/legal-notice.jsx" /* webpackChunkName: "component---src-pages-legal-advice-legal-notice-jsx" */),
  "component---src-pages-legal-advice-privacy-policy-jsx": () => import("./../../../src/pages/legal-advice/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-legal-advice-privacy-policy-jsx" */),
  "component---src-pages-legal-advice-reporting-channel-jsx": () => import("./../../../src/pages/legal-advice/reporting-channel.jsx" /* webpackChunkName: "component---src-pages-legal-advice-reporting-channel-jsx" */),
  "component---src-pages-legal-notice-jsx": () => import("./../../../src/pages/legal-notice.jsx" /* webpackChunkName: "component---src-pages-legal-notice-jsx" */),
  "component---src-pages-premios-expansion-allfunds-esg-jsx": () => import("./../../../src/pages/premios-expansion-allfunds-esg.jsx" /* webpackChunkName: "component---src-pages-premios-expansion-allfunds-esg-jsx" */),
  "component---src-pages-products-allfunds-navigator-jsx": () => import("./../../../src/pages/products/allfunds-navigator.jsx" /* webpackChunkName: "component---src-pages-products-allfunds-navigator-jsx" */),
  "component---src-pages-products-custom-reporting-jsx": () => import("./../../../src/pages/products/custom-reporting.jsx" /* webpackChunkName: "component---src-pages-products-custom-reporting-jsx" */),
  "component---src-pages-products-due-diligence-portal-jsx": () => import("./../../../src/pages/products/due-diligence-portal.jsx" /* webpackChunkName: "component---src-pages-products-due-diligence-portal-jsx" */),
  "component---src-pages-products-esg-everything-jsx": () => import("./../../../src/pages/products/esg-everything.jsx" /* webpackChunkName: "component---src-pages-products-esg-everything-jsx" */),
  "component---src-pages-products-esg-pai-jsx": () => import("./../../../src/pages/products/esg/pai.jsx" /* webpackChunkName: "component---src-pages-products-esg-pai-jsx" */),
  "component---src-pages-products-financial-market-portal-jsx": () => import("./../../../src/pages/products/financial-market-portal.jsx" /* webpackChunkName: "component---src-pages-products-financial-market-portal-jsx" */),
  "component---src-pages-products-fund-insight-jsx": () => import("./../../../src/pages/products/fund-insight.jsx" /* webpackChunkName: "component---src-pages-products-fund-insight-jsx" */),
  "component---src-pages-products-fund-registration-jsx": () => import("./../../../src/pages/products/fund-registration.jsx" /* webpackChunkName: "component---src-pages-products-fund-registration-jsx" */),
  "component---src-pages-products-golden-data-hub-index-jsx": () => import("./../../../src/pages/products/golden-data-hub/index.jsx" /* webpackChunkName: "component---src-pages-products-golden-data-hub-index-jsx" */),
  "component---src-pages-products-golden-data-hub-request-info-jsx": () => import("./../../../src/pages/products/golden-data-hub/request-info.jsx" /* webpackChunkName: "component---src-pages-products-golden-data-hub-request-info-jsx" */),
  "component---src-pages-products-nextportfolio-4-jsx": () => import("./../../../src/pages/products/nextportfolio4.jsx" /* webpackChunkName: "component---src-pages-products-nextportfolio-4-jsx" */),
  "component---src-pages-products-regulatory-reporting-jsx": () => import("./../../../src/pages/products/regulatory-reporting.jsx" /* webpackChunkName: "component---src-pages-products-regulatory-reporting-jsx" */),
  "component---src-pages-products-share-class-radar-jsx": () => import("./../../../src/pages/products/share-class-radar.jsx" /* webpackChunkName: "component---src-pages-products-share-class-radar-jsx" */),
  "component---src-pages-products-sustainability-navigator-jsx": () => import("./../../../src/pages/products/sustainability-navigator.jsx" /* webpackChunkName: "component---src-pages-products-sustainability-navigator-jsx" */),
  "component---src-pages-segments-banks-wealth-managers-index-jsx": () => import("./../../../src/pages/segments/banks-wealth-managers/index.jsx" /* webpackChunkName: "component---src-pages-segments-banks-wealth-managers-index-jsx" */),
  "component---src-pages-segments-fund-houses-index-jsx": () => import("./../../../src/pages/segments/fund-houses/index.jsx" /* webpackChunkName: "component---src-pages-segments-fund-houses-index-jsx" */),
  "component---src-pages-segments-insurance-pensions-allianz-jsx": () => import("./../../../src/pages/segments/insurance-pensions/allianz.jsx" /* webpackChunkName: "component---src-pages-segments-insurance-pensions-allianz-jsx" */),
  "component---src-pages-segments-insurance-pensions-generali-jsx": () => import("./../../../src/pages/segments/insurance-pensions/generali.jsx" /* webpackChunkName: "component---src-pages-segments-insurance-pensions-generali-jsx" */),
  "component---src-pages-segments-insurance-pensions-index-jsx": () => import("./../../../src/pages/segments/insurance-pensions/index.jsx" /* webpackChunkName: "component---src-pages-segments-insurance-pensions-index-jsx" */),
  "component---src-pages-segments-insurance-pensions-mapfre-jsx": () => import("./../../../src/pages/segments/insurance-pensions/mapfre.jsx" /* webpackChunkName: "component---src-pages-segments-insurance-pensions-mapfre-jsx" */),
  "component---src-pages-solutions-allfunds-navigator-jsx": () => import("./../../../src/pages/solutions/allfunds-navigator.jsx" /* webpackChunkName: "component---src-pages-solutions-allfunds-navigator-jsx" */),
  "component---src-pages-solutions-blockchain-jsx": () => import("./../../../src/pages/solutions/blockchain.jsx" /* webpackChunkName: "component---src-pages-solutions-blockchain-jsx" */),
  "component---src-pages-solutions-distribution-platform-jsx": () => import("./../../../src/pages/solutions/distribution-platform.jsx" /* webpackChunkName: "component---src-pages-solutions-distribution-platform-jsx" */),
  "component---src-pages-solutions-regulatory-solutions-jsx": () => import("./../../../src/pages/solutions/regulatory-solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-regulatory-solutions-jsx" */),
  "component---src-pages-solutions-wealth-solutions-jsx": () => import("./../../../src/pages/solutions/wealth-solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-wealth-solutions-jsx" */),
  "component---src-pages-widgets-news-vertical-index-jsx": () => import("./../../../src/pages/widgets/news/vertical/index.jsx" /* webpackChunkName: "component---src-pages-widgets-news-vertical-index-jsx" */),
  "component---src-pages-widgets-price-horizontal-big-index-jsx": () => import("./../../../src/pages/widgets/price/horizontal/big/index.jsx" /* webpackChunkName: "component---src-pages-widgets-price-horizontal-big-index-jsx" */),
  "component---src-pages-widgets-price-horizontal-index-jsx": () => import("./../../../src/pages/widgets/price/horizontal/index.jsx" /* webpackChunkName: "component---src-pages-widgets-price-horizontal-index-jsx" */),
  "component---src-pages-widgets-price-horizontal-light-big-jsx": () => import("./../../../src/pages/widgets/price/horizontal/light/big.jsx" /* webpackChunkName: "component---src-pages-widgets-price-horizontal-light-big-jsx" */),
  "component---src-pages-widgets-price-horizontal-light-index-jsx": () => import("./../../../src/pages/widgets/price/horizontal/light/index.jsx" /* webpackChunkName: "component---src-pages-widgets-price-horizontal-light-index-jsx" */),
  "component---src-pages-widgets-price-mini-index-jsx": () => import("./../../../src/pages/widgets/price/mini/index.jsx" /* webpackChunkName: "component---src-pages-widgets-price-mini-index-jsx" */),
  "component---src-pages-widgets-price-mini-light-index-jsx": () => import("./../../../src/pages/widgets/price/mini/light/index.jsx" /* webpackChunkName: "component---src-pages-widgets-price-mini-light-index-jsx" */),
  "component---src-pages-widgets-price-vertical-index-jsx": () => import("./../../../src/pages/widgets/price/vertical/index.jsx" /* webpackChunkName: "component---src-pages-widgets-price-vertical-index-jsx" */),
  "component---src-pages-widgets-price-vertical-light-index-jsx": () => import("./../../../src/pages/widgets/price/vertical/light/index.jsx" /* webpackChunkName: "component---src-pages-widgets-price-vertical-light-index-jsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-whitepaper-js": () => import("./../../../src/templates/whitepaper.js" /* webpackChunkName: "component---src-templates-whitepaper-js" */)
}

